import { trpc } from "@/common/trpc";
import { Amplify } from "aws-amplify";
import dayjs from "dayjs";
import type { AppProps } from "next/app";
import Router, { useRouter } from "next/router";
import Script from "next/script";
import NProgress from "nprogress"; //nprogress module
import "nprogress/nprogress.css"; //styles of nprogress
import { useEffect, useState } from "react";
import { Provider } from "react-redux";
import { ThemeProvider, createGlobalStyle } from "styled-components";
import {
  COOKIE_ACCEPT_KEY,
  CookieOverlay,
  SMECookie,
  cookieManager,
} from "../../../shared/components/global/CookieOverlay";
import { facebookPageview } from "../../../shared/lib/fpixel";
import { GA_TRACKING_ID, GTM_ID, gtagPageview } from "../../../shared/lib/gtag";
import { device } from "../../../shared/styles/device";
import awsExports from "../aws-exports";
import store from "../redux/store";
import "../styles/globals.css";
import { theme } from "../styles/theme";
export { reportWebVitals } from "next-axiom";

require("dayjs/locale/de");
dayjs.locale("de");

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  .custom-swal-scrolling {
    width: 95%;
    animation: none;

    @media ${device.tablet} {
      width: 60%;
      max-height: 80vh;
      overflow-y: scroll;
    }
  }
`;

let isLocalhost = false;
let isStaging = false;

if (typeof window !== "undefined") {
  isLocalhost = Boolean(
    window.location.hostname === "localhost" ||
      // [::1] is the IPv6 localhost address.
      window.location.hostname === "[::1]" ||
      // 127.0.0.1/8 is considered localhost for IPv4.
      window.location.hostname.match(
        /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
      )
  );
  isStaging = Boolean(
    window.location.hostname === "talentpool-staging.netlify.app"
  );
}

// Assuming you have two redirect URIs, and the first is for localhost and second is for production
const [productionRedirectSignIn, localRedirectSignIn, stagingRedirectSignIn] =
  awsExports.oauth.redirectSignIn.split(",");

const [
  productionRedirectSignOut,
  localRedirectSignOut,
  stagingRedirectSignOut,
] = awsExports.oauth.redirectSignOut.split(",");

const updatedAwsConfig = {
  ...awsExports,
  Auth: {
    identityPoolId: "eu-central-1:eaabc2d1-67cc-44cd-a027-84eb6543758d",
    region: "eu-central-1",
    userPoolId: "eu-central-1_1YOLHOc9S",
    mandatorySignIn: false,
    userPoolWebClientId: "444f8vdtujr54b2trg46f3771p",
  },
  oauth: {
    ...awsExports.oauth,
    redirectSignIn: isLocalhost
      ? localRedirectSignIn
      : isStaging
      ? stagingRedirectSignIn
      : productionRedirectSignIn,
    redirectSignOut: isLocalhost
      ? localRedirectSignOut
      : isStaging
      ? stagingRedirectSignOut
      : productionRedirectSignOut,
  },
};

Amplify.configure(updatedAwsConfig);

Router.events.on("routeChangeStart", () => NProgress.start());
Router.events.on("routeChangeComplete", () => NProgress.done());
Router.events.on("routeChangeError", () => NProgress.done());

function App({ Component, pageProps }: AppProps) {
  const [cookies, setCookies] = useState<SMECookie>();
  const router = useRouter();

  useEffect(() => {
    if (cookies == null) {
      const cookies: SMECookie = cookieManager.get(COOKIE_ACCEPT_KEY);

      if (cookies != null) {
        setCookies(cookies);
      }

      if (cookies?.acceptedTracking) {
        // This pageview only triggers the first time (its important for Pixel to have real information)
        facebookPageview();
        const handleRouteChange = (url) => {
          gtagPageview(url);
          facebookPageview();
        };
        router.events.on("routeChangeComplete", handleRouteChange);
        return () => {
          router.events.off("routeChangeComplete", handleRouteChange);
        };
      }
    }
  }, [router.events, cookies]);

  return (
    <>
      <GlobalStyle />
      {cookies?.acceptedTracking && (
        <>
          {/* Global Site Tag (gtag.js) - Google Analytics */}
          <Script
            id="gtag-init-1"
            strategy="afterInteractive"
            src={`https://www.googletagmanager.com/gtag/js?id=${GA_TRACKING_ID}`}
          />
          <Script
            id="gtag-init-2"
            strategy="afterInteractive"
            dangerouslySetInnerHTML={{
              __html: `
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());

                gtag('config', '${GA_TRACKING_ID}', {
                  page_path: window.location.pathname,
                });
              `,
            }}
          />
          {/* Google Tag Manager - Global base code */}
          <Script
            id="gtm-global-base-code"
            strategy="afterInteractive"
            dangerouslySetInnerHTML={{
              __html: `
                (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                })(window,document,'script','dataLayer', '${GTM_ID}');
              `,
            }}
          />
          <Script
            id="facebook-pixel"
            strategy="afterInteractive"
            dangerouslySetInnerHTML={{
              __html: `!function(f,b,e,v,n,t,s)
  {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
  n.callMethod.apply(n,arguments):n.queue.push(arguments)};
  if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
  n.queue=[];t=b.createElement(e);t.async=!0;
  t.src=v;s=b.getElementsByTagName(e)[0];
  s.parentNode.insertBefore(t,s)}(window, document,'script',
  'https://connect.facebook.net/en_US/fbevents.js');
  fbq('init', '1085967205398098');
  fbq('track', 'PageView');`,
            }}
          />
        </>
      )}
      <ThemeProvider theme={theme}>
        <CookieOverlay
          onAccept={(cookies: SMECookie) => {
            setCookies(cookies);
          }}
          domain=".stellmichein.de"
        />
        <Provider store={store}>
          <Component {...pageProps} />
        </Provider>
      </ThemeProvider>
    </>
  );
}
export default trpc.withTRPC(App);
