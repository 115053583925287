import styled from 'styled-components';

interface InputProps {
    error?: boolean;
    big?: boolean;
}

export const RequiredSignWrapper = styled.label`
    color: #E30000;
`;

export const InputTextbox = styled.label`
    position: relative;
    display: block;
    width: 100%;

    span {
        position: absolute;
        pointer-events: none;
        transition-timing-function: ease-in;
        transition-duration: .125s;
        top: 18px;
        white-space: nowrap;
        overflow: hidden;
        max-width: calc(100% - 32px);
        left: 1rem;
        font-size: 17px;
        line-height: 21px;
        color: #75757A;
    }

        .form-textbox-input.focused~.form-textbox-label, 
    .form-textbox-input.form-textarea-with-placeholder~.form-textbox-label, 
    .form-textbox-input.form-textbox-entered~.form-textbox-label, 
    .form-textbox-input:focus~.form-textbox-label, 
    .form-textbox-input:valid[required]~.form-textbox-label, 
    .form-textbox-input[placeholder]:not(:placeholder-shown)~.form-textbox-label, 
    .form-textbox-input[placeholder]:not([placeholder=" "])~.form-textbox-label {
        font-size: 12px;
        line-height: 16px;
        color: #6E6E73;
        top: 10px;
        left: 17px;
    }
`;

export const InputTextboxDate = styled.label`
    position: relative;
    display: block;
    width: 100%;

    span {
        position: absolute;
        pointer-events: none;
        transition-timing-function: ease-in;
        transition-duration: .125s;
        top: 18px;
        white-space: nowrap;
        overflow: hidden;
        max-width: calc(100% - 32px);
        left: 1rem;
        font-size: 17px;
        line-height: 21px;
        color: #75757A;
    }

        .form-textbox-input~.form-textbox-label, 
    .form-textbox-input.form-textarea-with-placeholder~.form-textbox-label, 
    .form-textbox-input.form-textbox-entered~.form-textbox-label, 
    .form-textbox-input:focus~.form-textbox-label, 
    .form-textbox-input:valid[required]~.form-textbox-label, 
    .form-textbox-input[placeholder]:not(:placeholder-shown)~.form-textbox-label, 
    .form-textbox-input[placeholder]:not([placeholder=" "])~.form-textbox-label {
        font-size: 12px;
        line-height: 16px;
        color: #6E6E73;
        top: 10px;
        left: 17px;
    }
`;
export const SelectTextbox = styled.div`
    position: relative;
    display: block;
    width: 100%;

    span {
        position: absolute;
        pointer-events: none;
        transition-timing-function: ease-in;
        transition-duration: .125s;
        top: 18px;
        white-space: nowrap;
        overflow: hidden;
        max-width: calc(100% - 32px);
        left: 1rem;
        font-size: 17px;
        line-height: 21px;
        color: #86868b;
    }
    
    .form-dropdown-select~.form-dropdown-label {
        font-size: 12px;
        line-height: 16px;
        color: #6E6E73;
        top: 10px;
        left: 17px;
    }
`;
export const Input = styled.input<InputProps>`
    box-sizing: border-box;
    -moz-box-sizing: border-box; 
    -webkit-box-sizing: border-box;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-color: #FFFFFF;
    border: ${(props) => props.error ? '2px' : '1px'}  solid ${(props) => props.error ? props.theme.colors.error : '#D2D2D7'};
    border-radius: 12px;
    color: ${(props) => props.theme.colors.fontBlack};
    display: inline-block;
    font-size: 17px;
    line-height: 23px;
    min-height: 56px;
    height: 56px;
    font-weight: 400;
    margin-bottom: 0;
    padding-top: 18px;
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 0;
    width: 100%;
    text-align: left !important;
    direction: ltr;
    outline: none;
    box-shadow: none;
    -moz-appearance: textfield;

    .form-textbox.is-error .form-textarea:not(.focused), 
    .form-textbox.is-error .form-textbox-input:not(:focus) {
        border-color: #e30000;
        background-color: #fff2f4;
    }

    &::-webkit-date-and-time-value {
        text-align: left !important;
        line-height: 1.23543;
        margin: 0;
        padding: 8px 12px;
        padding-left: 0;
    }

    &::placeholder {
        color: ${(props) => props.theme.colors.fontLight};
    }

    &:focus {
        outline: none;
        box-shadow: ${(props) => props.error ? 'none' : "0 0 0 3px " + props.theme.colors.primary};
    }

    &:disabled {
        background-color:#F5F5F7;
        color: ${(props) => props.theme.colors.fontLight};
    }
`;
export const Select = styled.select<InputProps>`
    background-color: #FFFFFF;
    border: ${(props) => props.error ? '2px' : '1px'}  solid ${(props) => props.error ? props.theme.colors.error : '#D2D2D7'};
    border-radius: 12px;
    color: ${(props) => props.theme.colors.fontBlack};
    display: inline-block;
    font-weight: 400;    
    font-size: 17px;
    line-height: 23px;
    height: 56px;
    margin-bottom: 0;
    padding-top: 18px;
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 0;
    box-sizing: border-box;
    width: 100%;
    text-align: left;
    direction: ltr;
    outline: none;
    box-shadow: none;
    background: url("/nav-down.png") no-repeat right #FFFFFF;
    background-size: 13px 13px;
    background-position-x: 97%;
    -webkit-appearance: none;

    &::placeholder {
        color: ${(props) => props.theme.colors.fontLight};
    }

    &:focus {
        outline: none;
        box-shadow: ${(props) => props.error ? 'none' : "0 0 0 3px " + props.theme.colors.primary};
    }
`;

export const Checkbox = styled.input<InputProps>`
    height: ${(props) => props.big ? '20px' : '16px'};
    width: ${(props) => props.big ? '20px' : '16px'};
    min-width: ${(props) => props.big ? '20px' : '16px'};
    min-height: ${(props) => props.big ? '20px' : '16px'};
    margin-right: 1rem;
`;

export const FilterCheckbox = styled.div`
    margin-bottom: 12px;

    input {
        margin-right: 6px;
        height: 18px;
        width: 18px;
        min-height: 18px;
        min-width: 18px;
        cursor: pointer;
    }

    label {
        font-weight: 400;
        font-size: 14px;
        line-height: 19px;
        letter-spacing: -0.03px;
        display: flex;
        align-items: center;
        cursor: pointer;
    }

    label:hover {
        color: ${props => props.theme.colors.secondary};
    }
`;

export const AGBTextbox = styled.div`
    margin-bottom: 12px;
    white-space: pre;

    input {
        margin-right: 6px;
        height: 18px;
        width: 18px;
        min-height: 18px;
        min-width: 18px;
        cursor: pointer;
    }

    label {
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        letter-spacing: -0.03px;
        display: flex;
        align-items: center;
        cursor: pointer;
    }
`;

export const Textarea = styled.textarea<InputProps>`
    background-color: #FFF;
    border: ${(props) => props.error ? '2px' : '1px'}  solid ${(props) => props.error ? props.theme.colors.error : '#EFF0F7'};
    border-radius: 16px;
    color: ${(props) => props.theme.colors.fontBlack};
    display: inline-block;
    font-size: 17px;
    line-height: 23px;
    font-weight: 400;
    margin-bottom: 0;
    padding-top: 27px;
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 0;
    box-sizing: border-box;
    width: 100%;
    text-align: left;
    direction: ltr;
    outline: none;
    box-shadow: none;
    resize: vertical;

    &::placeholder {
        color: ${(props) => props.theme.colors.fontLight};
    }

    &:focus {
        outline: none;
        box-shadow: ${(props) => props.error ? 'none' : "0 0 0 3px " + props.theme.colors.primary};
    }
`;