export interface Talent {
  owner?: string;
  first_name: string;
  last_name: string;
  contactEmail?: string;
  birthdate: string;
  email: string;
  disziplin: string;
  phone: string;
  residence?: string;
  city: string[];
  current_education: string;
  current_education_year: string;
  current_education_type: string;
  highest_degree: string;
  highest_degree_year: string;
  last_employers: string[];
  type_of_employment: string;
  earliest_starting_date: string;
  type_of_search: string;
  gallery_images: string[];
  imageUrl?: string;
  s3ImagePath?: string;
  s3DocumentPath?: any;
  s3TextUploadPath?: any;
  updatedAt?: string;
  design_upload_url?: string;
  text_upload_url?: string;
  discipline_specific_task?: string;
  work_areas?: string;
  favorite_work_tasks?: string;
  discipline_subcategories: string[];
  category_term: string;
  languages: string[];
  source: string;
  show_in_talentpool: boolean;
  isCsvImported: boolean;
  event_registrations?: any;
  reminderEmailDateSent?: string;
  updatedProfileAt?: string;
}

export enum TalentHomeMode {
  READ,
  EDIT,
}

export const getFullnameForKey = (key: string) => {
  switch (key) {
    case "first_name":
      return "Vorname";
    case "last_name":
      return "Vorname";
    case "contactEmail":
      return "E-Mail zur Kontaktaufnahme";
    case "residence":
      return "Wohnort";
    case "phone":
      return "Telefonnummer";
    case "birthdate":
      return "Geburtsdatum";
    case "highest_degree":
      return "Studiengang/Ausbildungsberuf";
    case "highest_degree_year":
      return "Jahr des höchsten Abschlusses";
    case "current_education_year":
      return "Aktuelles Semester bzw. Ausbildungsjahr";
    case "current_education_type":
      return "Ausbildungsniveau";
    case "languages":
      return "Sprachen";
    case "city":
      return "Arbeitsorte";
    case "category_term":
      return "Arbeitskategorie";
    case "last_employers":
      return "Letzten Arbeitgeber*innen";
    case "disziplin":
      return "Disziplin";
    case "discipline_subcategories":
      return "Spezialgebiete";
    case "design_upload_url":
      return "Design Upload";
    case "text_upload_url":
      return "Text Upload";
    case "favorite_work_tasks":
      return "Welche Aufgaben erledigst du besonders gerne? (optional)";
    case "work_areas":
      return "Welche Aufgaben gehörten zu deinem Arbeitsbereich? (optional)";
    case "discipline_specific_task":
      return "Disziplin-spezifische Frage";
    case "type_of_employment":
      return "Anstellungsart";
    case "earliest_starting_date":
      return "Frühester Arbeitsbegin";
    case "type_of_search":
      return "Status deiner Suche";
    case "source":
      return "Wie bist du auf STELL-MICH-EIN aufmerksam geworden?";
    default:
      return key;
  }
};
