import { createSlice } from '@reduxjs/toolkit'
import { Job } from '../../../shared/model/Job'
import type { AppState } from './store'

export interface CreateJobState {
    value: Job
}

const initialJob: Job = {
    slug: "",
    title: "",
    category: "",
    employmentType: "",
    city: "",
    remoteType: "",
    careerLevel: "",
    description: "",
    linkToApply: "",
    emailContact: "",
    isHighlight: false,
    isColored: false,
    expiresAt: "",
    companyJobsId: ""
}

const initialState: CreateJobState = {
    value: initialJob
}

export const createJobSlice = createSlice({
    name: 'createJob',
    initialState,
    reducers: {
        setCreateJob: (state, action) => { state.value = action.payload },
        reset: () => initialState
    }
})

export const { reset, setCreateJob } = createJobSlice.actions

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectCreateJob = (state: AppState) => state.createJob.value

export default createJobSlice.reducer