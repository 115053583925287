export type CustomUserData = {
  email: string;
  phone: string;
  firstName: string;
  lastName: string;
  city: string;
};

const getCookieValue = (name) => {
  const match = document.cookie.match(new RegExp("(^| )" + name + "=([^;]+)"));
  return match ? match[2] : null;
};

const getFacebookIds = () => {
  const fbp = getCookieValue("_fbp");
  const fbc = getCookieValue("_fbc");
  return { fbp, fbc };
};

export const facebookPageview = () => {
  if (window.fbq != null) {
    const { fbp, fbc } = getFacebookIds();
    window.fbq("track", "PageView");
    capiCall("PageView", fbp, fbc);
  }
};
export const facebookViewContent = () => {
  if (window.fbq != null) {
    const { fbp, fbc } = getFacebookIds();
    window.fbq("track", "ViewContent");
    capiCall("ViewContent", fbp, fbc);
  }
};

export const facebookAddtocart = () => {
  if (window.fbq != null) {
    const { fbp, fbc } = getFacebookIds();
    window.fbq("track", "AddToCart");
    capiCall("AddToCart", fbp, fbc);
  }
};
export const facebookLead = () => {
  if (window.fbq != null) {
    const { fbp, fbc } = getFacebookIds();
    window.fbq("track", "Lead");
    capiCall("Lead", fbp, fbc);
  }
};
export const facebookCompleteRegistration = (
  customUserData?: CustomUserData
) => {
  if (window.fbq != null) {
    const { fbp, fbc } = getFacebookIds();
    window.fbq("track", "CompleteRegistration");
    capiCall("CompleteRegistration", fbp, fbc, customUserData);
  }
};

const capiCall = (
  eventName: string,
  fbp?: string | null,
  fpc?: string | null,
  customUserData?: CustomUserData
) => {
  fetch("/api/capi", {
    method: "POST",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      eventName: eventName,
      fbp: fbp,
      fbc: fpc,
      customUserData: customUserData,
    }),
  });
};

// https://developers.facebook.com/docs/facebook-pixel/advanced/  s
export const event = (name, options = {}) => {
  if (window.fbq != null) {
    window.fbq("track", name, options);
  }
};
