import { API, Auth, graphqlOperation, Storage } from "aws-amplify";
import { Company, CompanyLicenses } from "../../../shared/model/Company";
import { createCompany, createCompanyLicenses, updateCompany } from "../graphql/mutations";
import { getCompany } from "../graphql/queries";

export const executeUpdateCompany = async (company: Company, createNew: boolean) => {
    let operation = updateCompany;
    if (createNew) {
        operation = createCompany;
    }
    const response: any = await API.graphql(
        graphqlOperation(operation, {
            input: company
        }),
    );

    return response;
}

export const executeFetchCompany = async () => {
    const user = await Auth.currentAuthenticatedUser();
    const company: any = await API.graphql(
        graphqlOperation(getCompany, { owner: user.username }),
    );

    return company;
}

export const executeCreateCompanyLicenses = async (company: CompanyLicenses) => {
    const response: any = await API.graphql(
        graphqlOperation(createCompanyLicenses, {
            input: company
        }),
    );
    return response;
}

export const uploadFile = async (filename: string, file: any, username: string): Promise<string | null> => {
    const fileSplit = file?.name?.split('.');
    if (fileSplit?.length == null) {
        return null;
    }
    const fileEnding = fileSplit[fileSplit?.length - 1];
    const fileName = filename + '.' + fileEnding;
    console.log('uploading file to s3: ' + fileName + ' ...');

    // https://docs.aws.amazon.com/AWSJavaScriptSDK/latest/AWS/S3.html#upload-property
    const result: any = await Storage.put(fileName, file, {
        cacheControl: "public",
        metadata: {
            owner: username,
        },
    })
    console.log("s3 result", result)
    return result.key;
}
