import { useEffect, useState } from "react";
import styled from "styled-components";
import Cookies from "universal-cookie";
import { Button, ButtonSizes } from "../../styles/button";
import { Checkbox } from "../../styles/input";
import StyledLink from "./StyledLink";

export interface SMECookie {
  accepted: boolean;
  acceptedTracking: boolean;
}

const OverlayContainer = styled.div`
  position: fixed;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  z-index: 9998;
  vertical-align: middle;
  white-space: nowrap;
  max-height: 100%;
  max-width: 100%;
  overflow-x: auto;
  overflow-y: auto;
  text-align: center;
  -webkit-tap-highlight-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const DarkOverlayContainer = styled.div`
  position: fixed;
  z-index: 9997;
  right: 0;
  bottom: -200px;
  top: 0;
  left: 0;
  opacity: 0.7;
  background-color: #202124;
`;
const CookiesContainer = styled.div`
  border-radius: 16px;
  display: inline-block;
  z-index: 9999;
  background-color: #fff;
  text-align: left;
  white-space: normal;
  overflow: hidden;
  box-shadow: 0 2px 10px 0 rgb(0 0 0 / 20%);
  position: relative;
  vertical-align: middle;
  height: auto;
  width: 500px;
  padding: 32px;
`;
const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  row-gap: 16px;
  margin-top: 16px;
`;
const FooterContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  column-gap: 16px;
  align-items: center;
  justify-content: center;
  margin-top: 32px;

  a,
  p {
    color: ${(props) => props.theme.colors.fontLight};
  }
`;

export const CheckboxContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 16px;
  margin-bottom: 16px;

  label {
    font-size: 16px;
    font-weight: 500;
    color: ${(props) => props.theme.colors.fontBlack};
    text-align: left;
    margin: 0;
  }
`;
export const COOKIE_ACCEPT_KEY = "sme-cookie-accept";

interface CookieOverlayProps {
  onAccept: any;
  domain: string;
}

export const cookieManager = new Cookies();

export const CookieOverlay = ({ onAccept, domain }: CookieOverlayProps) => {
  const [visible, setVisible] = useState<boolean>(false);
  const [acceptTracking, setAcceptTracking] = useState<boolean>(false);

  const handleAcceptance = (acceptAll: boolean) => {
    let shouldAcceptTracking = acceptTracking;
    if (acceptAll) {
      shouldAcceptTracking = true;
    }
    const cookies: SMECookie = {
      accepted: true,
      acceptedTracking: shouldAcceptTracking,
    };

    cookieManager.set(COOKIE_ACCEPT_KEY, JSON.stringify(cookies), {
      domain: domain,
      path: "/",
    });

    setVisible(false);
    if (document != null) {
      document
        .getElementsByTagName("html")[0]
        ?.classList.remove("sme-no-scroll");
      document
        .getElementsByTagName("body")[0]
        ?.classList.remove("sme-no-scroll");
    }
    onAccept(cookies);
  };

  useEffect(() => {
    const cookies: SMECookie = cookieManager.get(COOKIE_ACCEPT_KEY);
    let accepted = false;
    if (cookies != null) {
      accepted = cookies?.accepted;
      // coming from talentpool.easyboarding.de subdomain
      // we can allow cookies here
      if (cookies?.acceptedTracking) {
        onAccept(cookies);
      }
    }
    if (
      typeof window != "undefined" &&
      window.location.host === "localhost:3001"
    ) {
      accepted = true;
    }
    setVisible(!accepted);
    if (!accepted && document != null) {
      document.getElementsByTagName("html")[0]?.classList.add("sme-no-scroll");
      document.getElementsByTagName("body")[0]?.classList.add("sme-no-scroll");
    }
  }, []);

  if (!visible) {
    return null;
  }

  return (
    <div role="dialog">
      <DarkOverlayContainer />
      <OverlayContainer>
        <CookiesContainer>
          <strong>Datenschutzeinstellungen</strong>
          <p>
            Wir nutzen Cookies auf unserer Website. Einige von Ihnen sind
            essentiell, während andere uns helfen, diese Website und ihre
            Erfahrung zu verbessern.
          </p>
          <CheckboxContainer>
            <Checkbox
              id="accept-cookies-checkbox"
              type="checkbox"
              checked={true}
              disabled
            />
            <label htmlFor="accept-cookies-checkbox">Essentiell</label>
          </CheckboxContainer>
          <CheckboxContainer>
            <Checkbox
              id="accept-cookies-tracking-checkbox"
              type="checkbox"
              checked={acceptTracking}
              onChange={() => setAcceptTracking(!acceptTracking)}
            />
            <label htmlFor="accept-cookies-tracking-checkbox">
              Statistiken
            </label>
          </CheckboxContainer>
          <ButtonContainer>
            <Button
              primary
              size={ButtonSizes.m}
              onClick={() => handleAcceptance(true)}
            >
              Alle akzeptieren
            </Button>
            <Button
              primary={false}
              size={ButtonSizes.m}
              onClick={() => handleAcceptance(false)}
            >
              Speichern
            </Button>
          </ButtonContainer>
          <FooterContainer>
            <StyledLink href="/datenschutz">Datenschutzerklärung</StyledLink>
            <StyledLink href="/impressum">Impressum</StyledLink>
          </FooterContainer>
        </CookiesContainer>
      </OverlayContainer>
    </div>
  );
};
