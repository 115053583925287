import Link, { LinkProps } from "next/link";
import styled from "styled-components";

interface StyledLinkProps extends LinkProps {
  children: any;
  onClick?: any;
  arialabel?: any;
  asas?: any;
}

const StyledLink = ({
  asas,
  children,
  href,
  arialabel,
  onClick,
}: StyledLinkProps) => (
  <Link
    href={href}
    as={asas}
    passHref
    aria-label={arialabel}
    onClick={() => (onClick != null ? onClick() : {})}
  >
    {children}
  </Link>
);

export default styled(StyledLink)`
  color: #0075e0;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
  display: inline-flex;

  &:hover {
    color: #40a9ff;
  }

  &:focus {
    color: #40a9ff;
    outline: none;
    border: 0;
  }
`;
