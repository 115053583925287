import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Storage } from "aws-amplify";
import { Talent, TalentHomeMode } from "../model/talentpool/talent";
import { executeFetchTalent } from "../services/TalentService";
import type { AppState } from "./store";

export interface TalentState {
  value: Talent;
  status: "idle" | "loading" | "failed";
  mode: TalentHomeMode | null;
  talentExists: boolean;
}

const initialTalent: Talent = {
  first_name: "",
  last_name: "",
  birthdate: "",
  email: "",
  disziplin: "",
  category_term: "",
  phone: "",
  residence: "",
  city: [],
  languages: [],
  current_education: "",
  current_education_year: "",
  current_education_type: "",
  highest_degree: "",
  highest_degree_year: "",
  last_employers: [],
  type_of_employment: "",
  earliest_starting_date: "",
  type_of_search: "",
  gallery_images: [],
  imageUrl: "",
  s3ImagePath: "",
  updatedAt: "",
  discipline_subcategories: [],
  work_areas: "",
  favorite_work_tasks: "",
  discipline_specific_task: "",
  source: "",
  show_in_talentpool: true,
  isCsvImported: false,
};

const initialState: TalentState = {
  value: initialTalent,
  status: "idle",
  mode: null,
  talentExists: false,
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const fetchTalentAsync = createAsyncThunk("talent/fetch", async () => {
  const response = await executeFetchTalent();
  let talent: Talent = response.data.getTalent;
  let mode: TalentHomeMode;
  let talentExists: boolean;

  let s3ImagePath;
  let s3DocumentPath;
  let s3TextUploadPath;

  if (talent != null) {
    if (talent?.imageUrl != null) {
      // try to get compressed version
      const compressedPathString =
        "compressed/" + talent?.imageUrl.split("/")[1];

      // TODO finish workaround...
      // no error is thrown because amplify return an invalid url here - great...
      // await Storage.get(key, { download: true }).then( (resp) => { return URL.createObjectURL(resp.Body) }).catch((err) => { // handle errors }) )

      s3ImagePath = await Storage.get(compressedPathString, { download: true })
        .then(async () => {
          // compressedS3ImagePath: any
          const url = await Storage.get(compressedPathString);
          return url;
        })
        .catch(async () => {
          // no compressed image available -> get uncompressed
          return await Storage.get(talent.imageUrl!!);
        });
    }
    if (talent?.design_upload_url) {
      s3DocumentPath = await Storage.get(talent?.design_upload_url, {
        download: true,
      });
    }
    if (talent?.text_upload_url) {
      s3TextUploadPath = await Storage.get(talent?.text_upload_url, {
        download: true,
      });
    }
    let birthdate = talent?.birthdate;
    if (birthdate != null && birthdate.includes(".")) {
      // assume dd.mm.yyyy format, we need to transform to yyyy-MM-dd for html date picker
      birthdate = birthdate.split(".").reverse().join("-");
    }
    if (birthdate.includes("-")) {
      const birthdateAsDate = new Date(birthdate);
      birthdate = birthdateAsDate.getFullYear().toString();
    }
    let earliest_starting_date = talent?.earliest_starting_date;
    if (
      earliest_starting_date != null &&
      earliest_starting_date.includes(".")
    ) {
      // assume dd.mm.yyyy format, we need to transform to yyyy-MM-dd for html date picker
      earliest_starting_date = earliest_starting_date
        .split(".")
        .reverse()
        .join("-");
    }
    // fixes for stupid dynamoDB restriction for not allowing empty arrays
    if (
      talent?.discipline_subcategories == null ||
      talent?.discipline_subcategories[0]?.length === 0
    ) {
      talent.discipline_subcategories = [];
    }
    if (
      talent?.last_employers == null ||
      talent?.last_employers[0]?.length === 0
    ) {
      talent.last_employers = [""];
    }
    mode = TalentHomeMode.READ;
    talentExists = true;
    talent = {
      disziplin: talent?.disziplin,
      source: talent?.source,
      languages: talent?.languages,
      category_term: talent?.category_term,
      contactEmail: talent?.contactEmail,
      first_name: talent?.first_name,
      last_name: talent?.last_name,
      birthdate: birthdate,
      email: talent?.email,
      phone: talent?.phone,
      residence: talent?.residence,
      city: talent?.city,
      current_education: talent?.current_education,
      current_education_year: talent?.current_education_year,
      current_education_type: talent?.current_education_type,
      highest_degree: talent?.highest_degree,
      highest_degree_year: talent?.highest_degree_year,
      last_employers: talent?.last_employers,
      type_of_employment: talent?.type_of_employment,
      earliest_starting_date: earliest_starting_date,
      type_of_search: talent?.type_of_search,
      gallery_images: talent?.gallery_images,
      favorite_work_tasks: talent?.favorite_work_tasks,
      discipline_specific_task: talent?.discipline_specific_task,
      design_upload_url: talent?.design_upload_url,
      text_upload_url: talent?.text_upload_url,
      work_areas: talent?.work_areas,
      s3ImagePath: s3ImagePath ? s3ImagePath : undefined,
      s3DocumentPath: s3DocumentPath?.Body ? s3DocumentPath.Body : undefined,
      s3TextUploadPath: s3TextUploadPath?.Body
        ? s3TextUploadPath.Body
        : undefined,
      updatedAt: talent?.updatedAt,
      discipline_subcategories: talent?.discipline_subcategories
        ? talent?.discipline_subcategories
        : [],
      show_in_talentpool: talent?.show_in_talentpool,
      isCsvImported: talent?.isCsvImported,
      event_registrations:
        talent?.event_registrations != null ? talent?.event_registrations : [],
    };
  } else {
    mode = TalentHomeMode.EDIT;
    talentExists = false;
    talent = initialTalent;
  }

  // The value we return becomes the `fulfilled` action payload
  return {
    talent: talent,
    talentExists: talentExists,
    mode: mode,
  };
});

export const talentSlice = createSlice({
  name: "talent",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    resetTalent: () => initialState,
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(fetchTalentAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchTalentAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.value = action.payload.talent;
        state.talentExists = action.payload.talentExists;
        state.mode = action.payload.mode;
      });
  },
});

export const { resetTalent } = talentSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectTalent = (state: AppState) => state.talent.value;
export const selectTalentMode = (state: AppState) => state.talent.mode;
export const selectTalentExists = (state: AppState) =>
  state.talent.talentExists;

export default talentSlice.reducer;
