/* eslint-disable no-shadow */
import styled from 'styled-components';
import { device } from "./device";

export enum ButtonSizes {
    s, m, l, xl, xxl
}

export enum SocialButtonType {
    google, facebook, apple
}
interface TextButtonProps {
    readonly primary?: boolean;
    readonly black?: boolean;
    readonly secondary?: boolean;
    readonly warning?: boolean;
    readonly size?: ButtonSizes;
    readonly iconRight?: boolean;
}
interface ButtonProps {
    readonly primary?: boolean;
    readonly error?: boolean;
    readonly inverted?: boolean;
    readonly size?: ButtonSizes;
    readonly $loading?: boolean;
    readonly $disabled?: boolean;
    readonly social?: SocialButtonType;
}

const fontSizeChooserMobile = (props: any) => {
    if (props.size === ButtonSizes.s) return '12px';
    if (props.size === ButtonSizes.m) return '14px';
    if (props.size === ButtonSizes.l) return '16px';
    if (props.size === ButtonSizes.xl) return '18px';
    if (props.size === ButtonSizes.xxl) return '20px';
    return '12px';
};
const lineHeightChooserMobile = (props: any) => {
    if (props.size === ButtonSizes.s) return '14px';
    if (props.size === ButtonSizes.m) return '16px';
    if (props.size === ButtonSizes.l) return '18px';
    if (props.size === ButtonSizes.xl) return '20px';
    if (props.size === ButtonSizes.xxl) return '22px';
    return '14px';
};
const paddingChooserMobile = (props: any) => {
    if (props.social == null) {
        if (props.size === ButtonSizes.s) return '10px 26px';
        if (props.size === ButtonSizes.m) return '12px 30px';
        if (props.size === ButtonSizes.l) return '14px 34px';
        if (props.size === ButtonSizes.xl) return '16px 39px';
        if (props.size === ButtonSizes.xxl) return '22px 54px';
    }
    if (props.social != null) {
        if (props.size === ButtonSizes.s) return '8px 26px';
        if (props.size === ButtonSizes.m) return '10px 30px';
        if (props.size === ButtonSizes.l) return '12px 34px';
        if (props.size === ButtonSizes.xl) return '14px 39px';
        if (props.size === ButtonSizes.xxl) return '20px 54px';
    }

    return '10px 26px';
};
const fontSizeChooser = (props: any) => {
    if (props.size === ButtonSizes.s) return '14px';
    if (props.size === ButtonSizes.m) return '16px';
    if (props.size === ButtonSizes.l) return '18px';
    if (props.size === ButtonSizes.xl) return '20px';
    if (props.size === ButtonSizes.xxl) return '22px';
    return '14px';
};
const lineHeightChooser = (props: any) => {
    if (props.size === ButtonSizes.s) return '12px';
    if (props.size === ButtonSizes.m) return '14px';
    if (props.size === ButtonSizes.l) return '16px';
    if (props.size === ButtonSizes.xl) return '18px';
    if (props.size === ButtonSizes.xxl) return '20px';
    return '12px';
};
const paddingChooser = (props: any) => {
    if (props.social == null) {
        if (props.size === ButtonSizes.s) return '16px 39px';
        if (props.size === ButtonSizes.m) return '18px 54px';
        if (props.size === ButtonSizes.l) return '22px 60px';
        if (props.size === ButtonSizes.xl) return '24px 60px';
        if (props.size === ButtonSizes.xxl) return '24px 60px';
    }
    if (props.social != null) {
        if (props.size === ButtonSizes.s) return '10px 30px';
        if (props.size === ButtonSizes.m) return '12px 34px';
        if (props.size === ButtonSizes.l) return '14px 39px';
        if (props.size === ButtonSizes.xl) return '20px 54px';
        if (props.size === ButtonSizes.xxl) return '22px 60px';
    }

    return '12px 30px';
};
const borderRadiusChooser = (props: any) => {
    if (props.size === ButtonSizes.s) return '14px';
    if (props.size === ButtonSizes.m) return '16px';
    if (props.size === ButtonSizes.l) return '18px';
    if (props.size === ButtonSizes.xl) return '20px';
    if (props.size === ButtonSizes.xxl) return '20px';
    return '16px';
};
const backgroundChooserSubtle = (props: any) => {

    if (props.disabled) {
        return props.theme.colors.primaryDisabled;
    }
    if (props.error) {
        return props.theme.colors.buttonSubtleError;
    }

    return props.theme.colors.buttonSubtle;
};
const backgroundChooser = (props: any) => {
    if (props.primary) {
        if (props.disabled) {
            return props.theme.colors.primaryDisabled;
        }
        return props.theme.colors.buttonPrimary;
    }
    if (props.social === SocialButtonType.facebook) {
        if (props.disabled) {
            return '#1877F2';
        }
        return '#1877F2';
    }
    if (props.social === SocialButtonType.google) {
        if (props.disabled) {
            return '#FFFFFF';
        }
        return '#FFFFFF';
    }
    if (props.social === SocialButtonType.apple) {
        if (props.disabled) {
            return '##000000';
        }
        return '#000000';
    }
    return props.theme.colors.buttonSubtle;
};

const colorChooserSubtle = (props: any) => {
    if (props.loading) {
        return 'transparent';
    }
    if (props.error) {
        return props.theme.colors.error;
    }
    return props.theme.colors.buttonPrimary;
}

const colorChooser = (props: any) => {
    if (props.loading) {
        return 'transparent';
    }
    if (props.primary || props.social === SocialButtonType.facebook || props.social === SocialButtonType.apple) {
        return props.theme.colors.fontWhite;
    }
    if (props.social === SocialButtonType.google) {
        return 'rgba(0,0,0,0.54)';
    }
    if (props.inverted) {
        return props.theme.colors.fontBlack;
    }
    return props.theme.colors.buttonPrimary;
}

const fontWeightChooser = (props: any) => {
    if (props.social === SocialButtonType.facebook) {
        return '400';
    }
    if (props.social === SocialButtonType.google) {
        return '500';
    }
    if (props.social === SocialButtonType.apple) {
        return '400';
    }
    return '600';
}
const borderChooser = (props: any) => {
    let border = '2px solid ';
    if (props.buttonPrimary) {
        if (props.disabled) {
            return border + props.theme.colors.primaryDisabled;
        }
        return border + props.theme.colors.buttonPrimary;
    }
    if (props.social === SocialButtonType.facebook) {
        if (props.disabled) {
            return border + '#1877F2';
        }
        return border + '#1877F2';
    }
    if (props.social === SocialButtonType.google) {
        if (props.disabled) {
            return border + '#FFFFFF';
        }
        return border + '#FFFFFF';
    }
    if (props.social === SocialButtonType.apple) {
        if (props.disabled) {
            return border + '#000000';
        }
        return border + '#000000';
    }
    if (props.inverted) {
        return border + props.theme.colors.fontBlack;
    }
    return border + props.theme.colors.buttonSubtle;
}

export const ButtonSubtle = styled.button<ButtonProps>`
    position: relative;
    align-items: center;
    justify-content: center;
    background-color: ${backgroundChooserSubtle};
    border: none;
    border-radius: ${borderRadiusChooser};
    box-sizing: border-box;
    color: ${colorChooserSubtle};
    cursor: ${(props) => props.$disabled ? 'unset' : 'pointer'};
    display: flex;
    font-size: ${fontSizeChooserMobile};
    font-weight: 700;
    height: fit-content;
    line-height: ${lineHeightChooserMobile};
    padding: ${paddingChooserMobile};
    text-align: center;
    transition: all 0.25s cubic-bezier(0.39, 0.575, 0.565, 1);
    opacity: ${(props) => (props.$disabled === true ? 0.65 : 1)} ;

    @media ${device.laptop} {
        font-size: ${fontSizeChooser};
        line-height: ${lineHeightChooser};
        padding: ${paddingChooser};
    }

    &:hover {
        background: ${(props) => (props.primary ? props.theme.colors.buttonPrimaryHover : (props.error ? props.theme.colors.buttonSubtleErrorHover : 'transparent'))};
        border: none;
        // border: 2px solid ${(props) => (props.primary ? props.theme.colors.primary : props.theme.colors.primaryLight)};
        color: ${(props) => props.error ? props.theme.colors.error : ((props.$loading ? 'transparent' : (props.primary ? props.theme.colors.fontWhite : props.theme.colors.primaryLight)))};

        &>svg>* {
            fill: ${(props) => (props.$loading ? 'transparent' : (props.primary ? props.theme.colors.fontWhite : props.theme.colors.primaryLight))};
            stroke: ${(props) => (props.$loading ? 'transparent' : (props.primary ? props.theme.colors.fontWhite : props.theme.colors.primaryLight))};
        }
    }

    &::after {
        ${({ $loading }) => $loading && `
            content: "";
            position: absolute;
            width: 16px;
            height: 16px;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            margin: auto;
            border: 3px solid transparent;
            border-top-color: #ffffff;
            border-right-color: #FFFFFF;
            border-bottom-color: #FFFFFF;
            border-radius: 50%;
            animation: button-loading-spinner 1s ease infinite;
        `}
    }

    @keyframes button-loading-spinner {
        from {
            transform: rotate(0turn);
        }

        to {
            transform: rotate(1turn);
        }
}

    &>svg {
        margin-right: 12px;
    }
    &>svg>* {
        fill: ${colorChooserSubtle};
        stroke: ${colorChooserSubtle};
    }
`;

export const Button = styled.button<ButtonProps>`
    position: relative;
    align-items: center;
    justify-content: center;
    background-color: ${backgroundChooser};
    border: none; // ${borderChooser}; 
    border-radius: ${borderRadiusChooser};
    box-sizing: border-box;
    color: ${colorChooser};
    cursor: pointer;
    display: flex;
    font-size: ${fontSizeChooserMobile};
    font-weight: ${fontWeightChooser};
    line-height: ${lineHeightChooserMobile};
    padding: ${paddingChooserMobile};
    text-align: center;
    transition: all 0.25s cubic-bezier(0.39, 0.575, 0.565, 1);

    ${(props) => (props.social === SocialButtonType.google || props.social === SocialButtonType.apple) && `
        box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.084), 0px 2px 3px rgba(0, 0, 0, 0.168);
    `};

    @media ${device.laptop} {
        font-size: ${fontSizeChooser};
        line-height: ${lineHeightChooser};
        padding: ${paddingChooser};
    }

    &:hover {
        background: ${(props) => (props.primary ? props.theme.colors.buttonPrimaryHover : 'transparent')};
        border: none;
        // border: 2px solid ${(props) => (props.primary ? props.theme.colors.primary : props.theme.colors.primaryLight)};
        color: ${(props) => (props.$loading ? 'transparent' : (props.primary ? props.theme.colors.fontWhite : props.theme.colors.primaryLight))};
    }

    &::after {
        ${({ $loading }) => $loading && `
            content: "";
            position: absolute;
            width: 16px;
            height: 16px;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            margin: auto;
            border: 3px solid transparent;
            border-top-color: #ffffff;
            border-right-color: #FFFFFF;
            border-bottom-color: #FFFFFF;
            border-radius: 50%;
            animation: button-loading-spinner 1s ease infinite;
        `}
    }

    @keyframes button-loading-spinner {
        from {
            transform: rotate(0turn);
        }

        to {
            transform: rotate(1turn);
        }
    }

    &>svg {
        height: 20px;
        width: 20px;
        min-height: 20px;
        min-width: 20px;
        margin-right: 12px;
    }
    svg>* {
        fill: ${colorChooser};
    }
`;

export const SocialButton = styled.button<ButtonProps>`
    position: relative;
    align-items: center;
    justify-content: center;
    background-color: ${backgroundChooser};
    border: ${borderChooser}; 
    border-radius: ${borderRadiusChooser};
    box-sizing: border-box;
    color: ${colorChooser};
    cursor: pointer;
    display: flex;
    font-size: ${fontSizeChooserMobile};
    font-weight: ${fontWeightChooser};
    line-height: ${lineHeightChooserMobile};
    padding: ${paddingChooserMobile};
    text-align: center;
    transition: all 0.25s cubic-bezier(0.39, 0.575, 0.565, 1);

    ${(props) => (props.social === SocialButtonType.google || props.social === SocialButtonType.apple) && `
        box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.084), 0px 2px 3px rgba(0, 0, 0, 0.168);
    `};

    @media ${device.laptop} {
        font-size: ${fontSizeChooser};
        line-height: ${lineHeightChooser};
        padding: ${paddingChooser};
    }

    &::after {
        ${({ $loading }) => $loading && `
            content: "";
            position: absolute;
            width: 16px;
            height: 16px;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            margin: auto;
            border: 3px solid transparent;
            border-top-color: #ffffff;
            border-right-color: #FFFFFF;
            border-bottom-color: #FFFFFF;
            border-radius: 50%;
            animation: button-loading-spinner 1s ease infinite;
        `}
    }

    @keyframes button-loading-spinner {
        from {
            transform: rotate(0turn);
        }

        to {
            transform: rotate(1turn);
        }
}
    &>svg {
        margin-right: 12px;
    }
`;

export const TextButton = styled.button<TextButtonProps>`
    position: relative;
    align-items: center;
    justify-content: center;
    background-color:  transparent;
    border: none;
    box-sizing: border-box;
    color: ${(props) => props.black ? props.theme.colors.fontBlack : props.secondary ? props.theme.colors.primary : (props.primary ? props.theme.colors.buttonLink : (props.warning ? props.theme.colors.error : props.theme.colors.fontWhite))};
    cursor: pointer;
    display: inline-flex;
    font-size: ${fontSizeChooserMobile};
    font-weight: 600;
    line-height: ${lineHeightChooserMobile};
    padding: 10px 0;
    text-align: center;
    transition: all 0.25s cubic-bezier(0.39, 0.575, 0.565, 1);

    @media ${device.laptop} {
        font-size: ${fontSizeChooser};
        line-height: ${lineHeightChooser};
        padding: 10px 0;
    }

    &:hover {
        color: ${(props) => props.primary ? props.theme.colors.buttonPrimaryHover : props.theme.colors.fontLight};
    }

    &:disabled {
        cursor: initial;
        color: ${(props) => props.theme.colors.fontLight};
    }


   span {
        ${(props) => props.iconRight ? 'margin-right: 12px;' : 'margin-left: 12px;'}
   }

    &>svg {
        height: 26px;
        width: 26px;
        min-height: 26px;
        min-width: 26px;
    }
    svg>* {
        fill: ${(props) => props.black ? props.theme.colors.fontBlack : (props.primary ? props.theme.colors.buttonPrimary : (props.warning ? props.theme.colors.error : props.theme.colors.fontWhite))};
    }
   svg {
       ${(props) => props.iconRight && 'transform: rotate(180deg);'}
   }

`;