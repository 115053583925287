import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { API } from 'aws-amplify'
import { Job } from '../../../shared/model/Job'
import { sortByHighlightJobs } from '../../../shared/utils/jobs'
import { searchJobs } from '../graphql/queries'
import type { AppState } from './store'

export interface JobsState {
    value: Job[] | null
    nextToken: any | null
    currentToken: any | null
    previousTokens: any[]
    status: 'idle' | 'loading' | 'failed'
}

const initialState: JobsState = {
    value: null,
    nextToken: null,
    currentToken: null,
    previousTokens: [],
    status: 'idle'
}

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const fetchJobsAsync = createAsyncThunk(
    'jobs/fetch',
    async (variables: any) => {
        const response: any = await API.graphql({
            query: searchJobs,
            variables: variables,
            authMode: 'API_KEY'
        });
        let jobs: Job[] = response.data.searchJobs?.items;
        jobs = jobs
            .filter(job => job.expiresAt != null && job.expiresAt?.length > 0 && new Date(job.expiresAt) > new Date());
        const nextToken = response.data.searchJobs?.nextToken
        sortByHighlightJobs(jobs);

        // The value we return becomes the `fulfilled` action payload
        return {
            jobs: jobs,
            nextToken: nextToken
        }
    }
)


export const jobsSlice = createSlice({
    name: 'jobssearch',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        // Use the PayloadAction type to declare the contents of `action.payload`
        /*         updateCompany: (state, action: PayloadAction<Company>) => {
                    state.value = action.payload
                }, */
    },
    // The `extraReducers` field lets the slice handle actions defined elsewhere,
    // including actions generated by createAsyncThunk or in other slices.
    extraReducers: (builder) => {
        builder
            .addCase(fetchJobsAsync.pending, (state) => {
                state.status = 'loading'
            })
            .addCase(fetchJobsAsync.fulfilled, (state, action) => {
                state.status = 'idle'
                state.value = action.payload.jobs
                state.currentToken = state.nextToken
                state.nextToken = action.payload.nextToken
            })
    },
})

function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
}

// export const { updateCompany } = companySlice.actions

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectJobs = (state: AppState) => state.jobs.value
/* export const selectCompaniesNextToken = (state: AppState) => state.companies.nextToken
export const selectCompaniesPrevTokens = (state: AppState) => state.companies.previousTokens */

export default jobsSlice.reducer
