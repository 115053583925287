import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { API } from 'aws-amplify'
import { Company } from '../../../shared/model/Company'
import { listCompanies } from '../graphql/queries'
import type { AppState } from './store'

export enum CompanyOperation {
    INITIAL, NEXT, PREV
}
export interface CompanyState {
    value: Company[] | null
    nextToken: any | null
    currentToken: any | null
    previousTokens: any[]
    status: 'idle' | 'loading' | 'failed'
    operation: CompanyOperation
}

const initialState: CompanyState = {
    value: null,
    nextToken: null,
    currentToken: null,
    previousTokens: [],
    status: 'idle',
    operation: CompanyOperation.INITIAL
}

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const fetchCompaniesAsync = createAsyncThunk(
    'companies/fetch',
    async (variables: any) => {
        const operation: CompanyOperation = variables.operation;
        delete variables.operation;
        const response: any = await API.graphql({
            query: listCompanies,
            variables: variables,
            authMode: 'API_KEY'
        });
        const companies: Company[] = response.data.listCompanies?.items;
        shuffleArray(companies);
        const nextToken = response.data.listCompanies?.nextToken

        // The value we return becomes the `fulfilled` action payload
        return {
            companies: companies,
            nextToken: nextToken,
            operation: operation
        }
    }
)


export const companiesSlice = createSlice({
    name: 'companies',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        // Use the PayloadAction type to declare the contents of `action.payload`
        /*         updateCompany: (state, action: PayloadAction<Company>) => {
                    state.value = action.payload
                }, */
    },
    // The `extraReducers` field lets the slice handle actions defined elsewhere,
    // including actions generated by createAsyncThunk or in other slices.
    extraReducers: (builder) => {
        builder
            .addCase(fetchCompaniesAsync.pending, (state) => {
                state.status = 'loading'
            })
            .addCase(fetchCompaniesAsync.fulfilled, (state, action) => {
                state.status = 'idle'
                state.value = action.payload.companies
                if (action.payload.operation === CompanyOperation.INITIAL) {
                    state.currentToken = state.nextToken
                }
                if (action.payload.operation === CompanyOperation.NEXT) {
                    const previousTokensCopy = state.previousTokens
                    previousTokensCopy.push(state.currentToken);
                    state.currentToken = state.nextToken
                    state.previousTokens = previousTokensCopy
                }
                if (action.payload.operation === CompanyOperation.PREV) {
                    const previousTokensCopy = state.previousTokens;
                    const newCurrentToken = previousTokensCopy.pop();
                    state.previousTokens = previousTokensCopy;
                    state.currentToken = newCurrentToken;
                }
                state.nextToken = action.payload.nextToken

            })
    },
})

function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
}

// export const { updateCompany } = companySlice.actions

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectCompanies = (state: AppState) => state.companies.value
export const selectCompaniesNextToken = (state: AppState) => state.companies.nextToken
export const selectCompaniesPrevTokens = (state: AppState) => state.companies.previousTokens

export default companiesSlice.reducer
