import { API, Auth, graphqlOperation } from "aws-amplify";
import { getTalent } from "../graphql/queries";

export const executeFetchTalent = async () => {
    const user = await Auth.currentAuthenticatedUser()
    const talent: any = await API.graphql(
        graphqlOperation(getTalent, { owner: user.username })
    );

    return talent;
}
