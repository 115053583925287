import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { API } from 'aws-amplify'
import { Job } from '../../../shared/model/Job'
import { sortByHighlightJobs } from '../../../shared/utils/jobs'
import { searchJobs } from '../graphql/queries'
import type { AppState } from './store'

export enum JobsOperation {
    INITIAL, NEXT, PREV
}
export interface JobsState {
    value: Job[] | null
    nextToken: any | null
    currentToken: any | null
    previousTokens: any[]
    status: 'idle' | 'loading' | 'failed',
    operation: JobsOperation,
    total: number | null,
    searchAggregates: any[] | null
}

const initialState: JobsState = {
    value: null,
    nextToken: null,
    currentToken: null,
    previousTokens: [],
    status: 'idle',
    operation: JobsOperation.INITIAL,
    total: null,
    searchAggregates: null
}

export const fetchJobsSearchAsync = createAsyncThunk(
    'jobssearch/fetch',
    async (variables: any) => {
        const operation: JobsOperation = variables.operation;
        delete variables.operation;
        const response: any = await API.graphql({
            query: searchJobs,
            variables: variables,
            authMode: 'API_KEY'
        });
        let jobs: Job[] = response.data.searchJobs?.items;
        jobs = jobs
            .filter(job => job.expiresAt != null && job.expiresAt?.length > 0 && new Date(job.expiresAt) > new Date());
        const nextToken = response.data.searchJobs?.nextToken
        const total: number = response.data.searchJobs?.total;
        const searchAggregates: any[] = response.data.searchJobs?.aggregateItems;

        sortByHighlightJobs(jobs);

        return {
            jobs: jobs,
            total: total,
            searchAggregates: searchAggregates,
            nextToken: nextToken,
            operation: operation
        }
    }
)

export const jobsSearchSlice = createSlice({
    name: 'jobssearch',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchJobsSearchAsync.pending, (state) => {
                state.status = 'loading'
            })
            .addCase(fetchJobsSearchAsync.fulfilled, (state, action) => {
                state.status = 'idle'
                state.value = action.payload.jobs
                if (action.payload.operation === JobsOperation.INITIAL) {
                    state.currentToken = null
                    state.previousTokens = []
                }
                if (action.payload.operation === JobsOperation.NEXT) {
                    const previousTokensCopy = state.previousTokens
                    previousTokensCopy.push(state.currentToken);
                    state.currentToken = state.nextToken
                    state.previousTokens = previousTokensCopy
                }
                if (action.payload.operation === JobsOperation.PREV) {
                    const previousTokensCopy = state.previousTokens;
                    const newCurrentToken = previousTokensCopy.pop();
                    state.previousTokens = previousTokensCopy;
                    state.currentToken = newCurrentToken;
                }
                state.nextToken = action.payload.nextToken
                state.total = action.payload.total
                state.searchAggregates = action.payload.searchAggregates
            })
    }
})

export const selectJobsSearchJobs = (state: AppState) => state.jobsSearch.value
export const selectJobsSearchTotal = (state: AppState) => state.jobsSearch.total
export const selectJobsSearchSearchAggregates = (state: AppState) => state.jobsSearch.searchAggregates
export const selectJobsSearchNextToken = (state: AppState) => state.jobsSearch.nextToken
export const selectJobsSearchPrevTokens = (state: AppState) => state.jobsSearch.previousTokens

export default jobsSearchSlice.reducer
