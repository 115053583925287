import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit'
import companiesReducer from './companiesSlice'
import companyReducer from './companySlice'
import createJobReducer from './createJobSlice'
import jobsSearchReducer from './jobsSearchSlice'
import jobsReducer from './jobsSlice'
import talentReducer from './talentSlice'

const reducer = {
    talent: talentReducer,
    company: companyReducer,
    companies: companiesReducer,
    jobs: jobsReducer,
    jobsSearch: jobsSearchReducer,
    createJob: createJobReducer,
}

export function makeStore() {
    return configureStore({
        reducer: reducer,
        middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }),
    })
}

const store = makeStore()

export type AppState = ReturnType<typeof store.getState>

export type AppDispatch = typeof store.dispatch

export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    AppState,
    unknown,
    Action<string>
>

export default store